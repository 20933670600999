var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('layout-report', {
    attrs: {
      "renderBackAndForthFooter": false,
      "isProgressDone": true,
      "showFooter": false
    }
  }, [_c('v-card', {
    staticClass: "mx-auto",
    attrs: {
      "max-width": "500px",
      "flat": ""
    }
  }, [_c('v-card-text', [_c('p', {
    staticClass: "display-1 text--primary"
  }, [_vm._v(_vm._s(_vm.title))]), _c('p', {
    domProps: {
      "innerHTML": _vm._s(_vm.description)
    }
  })]), !_vm.isAuthenticated ? _c('div', [_c('v-text-field', {
    staticClass: "mx-4",
    attrs: {
      "length": "6",
      "outlined": "",
      "label": _vm.textFieldLabel,
      "required": "",
      "data-test-form-password": ""
    },
    model: {
      value: _vm.verificationCode,
      callback: function callback($$v) {
        _vm.verificationCode = $$v;
      },
      expression: "verificationCode"
    }
  }), _c('v-card-actions', {
    staticClass: "px-4"
  }, [_c('v-btn', {
    staticClass: "submitButton",
    attrs: {
      "color": "success",
      "type": "submit",
      "x-large": "",
      "block": "",
      "disabled": !_vm.verificationCode,
      "loading": _vm.isLoading,
      "data-test-submit-button": ""
    },
    on: {
      "click": _vm.submit
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v(" mdi-send ")]), _vm._v(" " + _vm._s(_vm.$t("report.thgSuccess.btnText")) + " ")], 1)], 1)], 1) : _c('div', {
    staticClass: "pt-4 pl-4 pr-4",
    attrs: {
      "flat": "",
      "max-width": "500"
    }
  }, [_c('notifications'), _c('v-card-actions', [_c('v-btn', {
    attrs: {
      "color": "success",
      "large": "",
      "block": "",
      "data-test-submit-register": ""
    },
    on: {
      "click": _vm.newCar
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("report.thgSuccess.btnTextAlt")) + " ")])], 1), _c('v-card-actions', [_c('v-btn', {
    attrs: {
      "color": "success",
      "block": "",
      "large": "",
      "outlined": "",
      "data-test-submit-register": ""
    },
    on: {
      "click": _vm.goToVehicle
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("report.thgSuccess.vehicleOverview")) + " ")])], 1)], 1)], 1), _c('confirm-action-dialog', {
    attrs: {
      "persistent": true,
      "isDialogActive": _vm.isDialogActive,
      "fullscreen": false,
      "hideLeft": true,
      "hideRight": true,
      "title": _vm.$t('report.thgRating.title'),
      "subtitle": _vm.$t('report.thgRating.descriptionProcess')
    },
    on: {
      "close": _vm.closeDialog
    }
  }, [_c('thg-rating-form', {
    on: {
      "created": function created($event) {
        _vm.isDialogActive = false;
      }
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }