var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('rating-form', {
    attrs: {
      "title": _vm.title,
      "description": _vm.description,
      "ratingKey": _vm.ratingKey
    },
    on: {
      "created": _vm.goToTrustpilot
    }
  }, [_vm._t("default")], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }