/**
 * Copyright 2021 mmmint.ai info@mmmint.ai - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential to MMM Intelligence UG (haftungsbeschränkt).
 */
import { resizeImage } from "@/lib/files/imageResizer";
import { IImageUploaded } from "@/models/Image/IImageUploaded";
import { ReportImageType } from "@/models/Report/ReportImageType";
import { IAddress } from "@/models/User/IAddress";
import { IContact } from "@/models/User/IContact";
import { IUser } from "@/models/User/IUser";
import { MrfiktivHttpClientProvider } from "@/services/mrfiktiv/mrfiktiv-http-client.provider";
import { ReportType } from "@/store/enum/reportType";
import { ConfigModule } from "@/store/modules/config";
import Vue from "vue";
import { Report } from "../v1/Report";
import { HttpClient } from "../v1/http-client";
import { ArgumentException } from "./../../../lib/exceptions/ArgumentException";
import {
  MrfiktivCreateReportDtoGen as CreateReportDto,
  MrfiktivCreateReportImageDtoGen as CreateReportImageDto,
  MrfiktivImageIdViewmodelGen as ImageIdViewmodel,
  MrfiktivCreateAccidentDetailsDtoGen,
  MrfiktivCreateInsuranceDtoGen,
  MrfiktivCreatePoliceDetailsDtoGen,
  MrfiktivCreateReportAsPartnerDtoGen,
  MrfiktivCreateReportWittnesDtoGen,
  MrfiktivExportReportMetaDtoGen,
  MrfiktivImageEditDataDtoGen,
  MrfiktivLeasingDetailsGen,
  MrfiktivPageViewModelGen,
  MrfiktivRegistrationDtoGen,
  MrfiktivReportControllerFindAllParamsGen,
  MrfiktivReportViewModelGen,
  MrfiktivSharedReportViewmodelGen,
  MrfiktivUpdateReportDtoGen,
  MrfiktivUpdateReportImagesDtoGen
} from "./../v1/data-contracts";
import { ICreateReport } from "@/store/models/mrfiktiv/report";
import { IPreExistingDamageDetails } from "@/models/pre-existing-damage.entity";
import { LanguageCodeEnum } from "@/lib/enum/language-code.enum";
import { CoverageEnum } from "@/lib/enum/liability.enum";

/**
 * Service to talk to mrfiktiv report endpoint.
 */
export class ReportService {
  /**
   * The HttpClient containing the Axios Instance
   */
  client: HttpClient;

  /**
   * The proxy.
   */
  proxy: Report;

  /**
   * @class Initialize ReportService
   */
  constructor(clientProvider: MrfiktivHttpClientProvider) {
    this.client = clientProvider.client();
    this.proxy = new Report(this.client);
  }

  /**
   * Creates a new report (schadensmeldung.digital).
   *
   * @param createReport the report to be created
   * @param customer the user to create the report for
   * @param customerAddress the customers address
   * @param customerContact the customers contact information
   * @param numberplate the number plate
   * @param partnerName the partner to associate to the report
   * @returns the created report
   */
  async create(
    createReport: ICreateReport,
    customer: IUser,
    customerAddress: IAddress,
    customerContact: IContact,
    numberplate: string,
    partnerName: string,
    reportType: ReportType,
    witness: MrfiktivCreateReportWittnesDtoGen[],
    insurances: MrfiktivCreateInsuranceDtoGen[],
    body: { [x: string]: any }[],
    isCompany: boolean,
    companyName: string,
    isTaxDeductible: boolean,
    taxnumber: string,
    externalId: string,
    language: LanguageCodeEnum,
    policeDetails?: MrfiktivCreatePoliceDetailsDtoGen,
    accidentDetails?: MrfiktivCreateAccidentDetailsDtoGen,
    registration?: MrfiktivRegistrationDtoGen,
    leasing?: MrfiktivLeasingDetailsGen,
    preExistingDamageDetails?: IPreExistingDamageDetails,
    coverage?: CoverageEnum | null,
    exports: MrfiktivExportReportMetaDtoGen[] = []
  ): Promise<MrfiktivReportViewModelGen> {
    if (!partnerName) {
      throw new ArgumentException("partnerName");
    }

    const createReportDto: CreateReportDto = {
      customerAddress: { ...customerAddress },
      customerContact: {
        ...customerContact
      },
      customerName: `${customer.firstName} ${customer.lastName}`,
      firstName: customer.firstName,
      lastName: customer.lastName,
      isCompany: isCompany,
      companyName: companyName,
      isTaxDeductible: isTaxDeductible,
      taxnumber: taxnumber,
      externalId: externalId,
      damage: createReport.damageLocation as any,
      datePreference: createReport.datePreference,
      images: {
        cockpits: createReport.cockpits.map(x => x.uploadId),
        damages: createReport.damages.map(x => x.uploadId),
        damagesDetail: createReport.damagesDetail.map(x => x.uploadId),
        overviews: createReport.overviews.map(x => x.uploadId),
        registrations: createReport.registrations.map(x => x.uploadId)
      },
      message: createReport.message,
      marketingOptIn: createReport.isMarketingOptIn,
      numberplate: numberplate,
      reportType: reportType,
      wittness: witness,
      insurances: insurances,
      policeDetails: policeDetails,
      accidentDetails: accidentDetails,
      registrationResults: registration,
      leasing: leasing,
      preExistingDamageDetails: preExistingDamageDetails,
      forwardedBy: createReport.forwardedBy,
      language: language,
      exports
    };

    if (body.length) {
      createReportDto.body = body;
    }

    if (coverage) {
      createReportDto.coverage = coverage;
    }

    const response = await this.proxy.reportControllerCreate({ partnerName }, createReportDto);
    return response.data;
  }

  /**
   * Resizes an image if configure in `ConfigModule` see @see ConfigModule.isResizingImagesReport.
   * Uploads the image to the report controller upload image endpoint.
   *
   * @param image the image to be uploaded
   * @param type the image type
   * @param partnerName the partner name to add the image to
   * @returns the uploaded image result
   */
  async addImage(
    image: File,
    type: ReportImageType,
    partnerName: string,
    categoryKey?: string
  ): Promise<IImageUploaded> {
    try {
      if (ConfigModule.isResizingImagesReport) {
        const blob = await resizeImage(image, ConfigModule.reportImageResizingOptions);
        image = new File([blob], image.name, { type: blob.type });
      }
    } catch (error) {
      Vue.$log.error(error);
    }

    const createReportImageDto: CreateReportImageDto = {
      image: image,
      type: type as any
    };

    const response = await this.proxy.reportControllerUploadImage(partnerName, createReportImageDto);
    const uploadedImage = response.data as ImageIdViewmodel;

    return {
      uploadId: uploadedImage.imageId,
      file: image,
      isUploaded: true,
      type,
      categoryKey
    } as IImageUploaded;
  }

  /**
   * Geths the report documents as pages
   *
   * @param query
   * @returns
   */
  async getReportPaginated(
    query: MrfiktivReportControllerFindAllParamsGen
  ): Promise<MrfiktivPageViewModelGen & { data?: MrfiktivReportViewModelGen[] }> {
    return (await this.proxy.reportControllerFindAll(query)).data;
  }

  /**
   *
   * Gets a report by shared access signature
   *
   * @param partnerId
   * @param reportId
   * @param token
   * @returns
   */
  async getDocumentBySharedAccessToken(
    partnerId: string,
    reportId: string,
    token: string
  ): Promise<MrfiktivSharedReportViewmodelGen> {
    const shared = await this.proxy.reportControllerFindOneWithAccessSignature({
      partnerId,
      reportId,
      sct: token
    });

    return shared.data;
  }

  /**
   * Updates the report
   *
   * @param partnerId
   * @param reportId
   * @param mrfiktivUpdateReportDtoGen
   * @returns
   */
  async updateReport(partnerId: string, reportId: string, mrfiktivUpdateReportDtoGen: MrfiktivUpdateReportDtoGen) {
    const updated = await this.proxy.reportControllerUpdateReport(partnerId, reportId, mrfiktivUpdateReportDtoGen);

    return updated.data;
  }

  /**
   * Updates the images in the report
   *
   * @param partnerId
   * @param reportId
   * @param data a dto containing the ids of the images that should be in the report after the update
   * @returns
   */
  async updateReportImages(partnerId: string, reportId: string, data: MrfiktivUpdateReportImagesDtoGen) {
    const updated = await this.proxy.reportControllerUpdateReportImages(partnerId, reportId, data);

    return updated.data;
  }

  /**
   * add edit data (rotation/ crop) to an image in a report
   *
   * @param partnerId
   * @param reportId
   * @param imageId
   * @param data
   * @returns
   */
  async editReportImage(partnerId: string, reportId: string, imageId: string, data: MrfiktivImageEditDataDtoGen) {
    const updated = await this.proxy.reportControllerEditReportImage(partnerId, reportId, imageId, data);

    return updated.data;
  }

  async createAsPartner(partnerId: string, data: MrfiktivCreateReportAsPartnerDtoGen) {
    const craeted = await this.proxy.reportControllerCreateReportAsPartner(partnerId, data);

    return craeted.data;
  }

  async archiveMany(partnerId: string, ids: string[]) {
    const archived = await this.proxy.reportControllerArchiveMany(partnerId, { ids });

    return archived.data;
  }
}

export default new ReportService(new MrfiktivHttpClientProvider());
