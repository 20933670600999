var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.isAuthenticated ? _c('the-navigation-layout', {
    attrs: {
      "items": _vm.items
    }
  }) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }