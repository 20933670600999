/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { ThgExceptionViewmodelGen, ThgRatingViewmodelGen } from "./data-contracts";
import { HttpClient, RequestParams } from "./http-client";

export class GetMyRatings<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags Get my ratings
   * @name RatingMeControllerFindMyRatigns
   * @summary Get my ratings.
   * @request GET:/rating/me
   * @secure
   * @response `200` `(ThgRatingViewmodelGen)[]` The ratings of a user
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  ratingMeControllerFindMyRatigns = (params: RequestParams = {}) =>
    this.http.request<ThgRatingViewmodelGen[], ThgExceptionViewmodelGen>({
      path: `/rating/me`,
      method: "GET",
      secure: true,
      format: "json",
      ...params
    });
}
