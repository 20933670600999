import { IRating, RatingEntity } from "@/models/rating.entity";
import ratingMeService from "@/services/shared/ratingMeService";
import store from "@/store/VuexPlugin";
import { Action, Module, Mutation, VuexModule, getModule } from "vuex-module-decorators";
import { RatingModule } from "./rating.store";

@Module({
  dynamic: true,
  namespaced: true,
  name: "ratingMeStore",
  store
})
export class RatingMeStore extends VuexModule {
  _ratings: IRating[] = [];

  get all() {
    return this._ratings;
  }

  /**
   * Indicates if the user has current ratings.
   * - Returns `true` if at least one rating is less than 90 days old.
   * - Returns `false` if there are no ratings or all ratings are older than 90 days.
   *
   * Examples:
   * - No ratings: this.all = [] → isCurrentRating = false
   * - All ratings older than 90 days: this.all = [{ isOlderThan90Days: true }] → isCurrentRating = false
   * - At least one rating younger than 90 days: this.all = [{ isOlderThan90Days: false }] → isCurrentRating = true
   */
  get isCurrentRating() {
    const ninetyDaysAgo = new Date();
    ninetyDaysAgo.setDate(ninetyDaysAgo.getDate() - 90);
    return this.all.some(rating => new Date(rating.timestamp?.created || new Date()) > ninetyDaysAgo);
  }

  get askForFeedback() {
    if (RatingModule.isThrottled) {
      return false;
    }

    if (RatingModule.ratingSubmitted) {
      return false;
    }

    if (this.isCurrentRating) {
      return false;
    }

    return true;
  }

  @Mutation
  private _mutateAll(ratings: IRating[]) {
    this._ratings = ratings;
  }

  @Mutation
  private _mutateOne(rating: IRating) {
    this._ratings.push(rating);
  }

  @Action
  public setOne(rating: IRating) {
    this.context.commit("_mutateOne", rating);
  }

  @Action
  async getMyRatings() {
    const ratings = await ratingMeService.findMyRatigns();

    if (!ratings) {
      return;
    }

    const data = [];
    for (const entry of ratings) {
      data.push(new RatingEntity(entry));
    }

    this.context.commit("_mutateAll", ratings);
  }
}

export const RatingMeModule = getModule(RatingMeStore);
