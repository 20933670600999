







































import { getDefaultPartnerColor } from "@/lib/getDefaultPartnerColor";
import { RatingEntity } from "@/models/rating.entity";
import { RatingModule } from "@/store/modules/rating.store";
import { ReportModule } from "@/store/modules/report.store";
import { Component, Prop, Vue } from "vue-property-decorator";

const CREATED = "created";

@Component({
  components: {}
})
export default class RatingForm extends Vue {
  @Prop({ default: "default" })
  ratingKey!: string;

  @Prop({ default: "" })
  title!: string;

  @Prop({ default: "" })
  description!: string;

  ratingEntity = new RatingEntity();

  get label() {
    if (this.ratingEntity.rating < 3) {
      return this.$t("components.RatingForm.label.bad");
    } else if (this.ratingEntity.rating === 3) {
      return this.$t("components.RatingForm.label.medium");
    } else {
      return this.$t("components.RatingForm.label.good");
    }
  }

  get key() {
    if (RatingModule.ratingKey) {
      return RatingModule.ratingKey;
    }

    return this.ratingKey;
  }

  get isThrottled() {
    return RatingModule.isThrottled;
  }

  get isSubmitted() {
    return RatingModule.ratingSubmitted;
  }

  mounted() {
    this.ratingEntity = new RatingEntity();
  }

  async submitRating() {
    this.ratingEntity.key = this.key;
    try {
      await this.ratingEntity.createRating(ReportModule.partner.id || ReportModule.partner._id);
      RatingModule.setRatingSubmitted(true);
      this.$emit(CREATED, this.ratingEntity);
    } catch (error) {
      this.$log.error(error);
      this.$toast.error(this.$t("components.RatingForm.error"));
    }
  }

  get color() {
    return getDefaultPartnerColor();
  }
}
