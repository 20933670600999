






























































































































import Debug from "@/components/utility/Debug.vue";
import LayoutSimple from "@/layouts/LayoutSimple.vue";
import { AppContextEnum } from "@/lib/enum/appContext.enum";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { ConfigModule } from "@/store/modules/config";
import { ErrorLogModule } from "@/store/modules/error-log";
import { FeatureModule } from "@/store/modules/feature.store";
import { UserModule } from "@/store/modules/me-user.store";
import { Component } from "vue-property-decorator";

@Component({
  components: { LayoutSimple, Debug }
})
export default class Setting extends PermissionMixin {
  /**
   * List of settings to display as feature flags.
   * Temporary showing booking registration image upload.
   */
  public settings: ISettingItem[] = [
    {
      title: "views.Setting.admin.onlineBookingImages.title",
      subtitle: "views.Setting.admin.onlineBookingImages.description",
      get: FeatureModule.attachmentsOnlineBooking,
      set: FeatureModule.setAttachmentsOnlineBooking,
      isEnabled: true
    },
    {
      title: "views.Setting.isMapShown.title",
      subtitle: "views.Setting.isMapShown.description",
      get: FeatureModule.isMapShown,
      set: FeatureModule.setIsMapShown,
      isEnabled: true
    }
  ];

  /**
   * Getter if currently in THG context
   */
  get isThg() {
    return AppContextEnum.THG === ConfigModule.appContext;
  }

  /**
   * Getter if currently in report context
   */
  get isReportPortal() {
    return AppContextEnum.REPORT_PORTAL === ConfigModule.appContext;
  }

  /**
   * Getter if currently in report user facing app context
   */
  get isReport() {
    return AppContextEnum.REPORT === ConfigModule.appContext;
  }

  /**
   * List of thgSettings to display as feature flags.
   */
  public get thgSettings(): ISettingItem[] {
    const thgSettings = [];

    if (AppContextEnum.THG === ConfigModule.appContext || AppContextEnum.THG_PORTAL === ConfigModule.appContext) {
      thgSettings.push({
        title: "views.Setting.navigationColors.title",
        subtitle: "views.Setting.navigationColors.description",
        get: FeatureModule.isNavigationInPartnerColors,
        set: FeatureModule.setIsNavigationInPartnerColors,
        isEnabled: true
      });

      thgSettings.push({
        title: "views.Setting.vuetifyColor.title",
        subtitle: "views.Setting.vuetifyColor.description",
        get: FeatureModule.isVuetifyInPartnerColors,
        set: FeatureModule.setIsVuetifyInPartnerColors,
        isEnabled: true
      });

      thgSettings.push({
        title: "views.Setting.billingDistribution.title",
        subtitle: "views.Setting.billingDistribution.description",
        get: FeatureModule.isBillingDistributionEnabled,
        set: FeatureModule.setIsBillingDistributionEnabled,
        isEnabled: true
      });

      thgSettings.push({
        title: "components.thg.ThgChargingStationForm.evseOutletId",
        subtitle: "components.thg.ThgChargingStationForm.evseOutletIdHint",
        get: FeatureModule.isEvseEnabled,
        set: FeatureModule.setIsEvseEnabled,
        isEnabled: true
      });

      thgSettings.push({
        title: "Google Tag Manager: Hash User-Provided Data",
        subtitle: "Add sha256_email_address to user_data",
        get: FeatureModule.isGtmHashEmail,
        set: FeatureModule.setIsGtmHashEmail,
        isEnabled: true
      });
    }

    return thgSettings;
  }

  /**
   * schadensmeldung Portal Setting
   * List of settings to display as partner settings.
   * We display all "functions" here so that users with lacking permission see a "locked" feature for up-selling.
   */
  public get partnerSettings(): ISettingItem[] {
    return [
      {
        title: "views.Setting.sign.signSettings.title",
        subtitle: "views.Setting.sign.signSettings.description",
        get: true,
        set: () => undefined,
        isEnabled: this.canCreateSign,
        isDisabled: true
      },
      {
        title: "views.Setting.admin.onlineBooking.title",
        subtitle: "views.Setting.admin.onlineBooking.description",
        get: true,
        set: () => undefined,
        isEnabled: this.canManageBooking,
        isDisabled: true
      },
      {
        title: "views.Setting.fleet.vehicle.title",
        subtitle: "views.Setting.fleet.vehicle.description",
        get: true,
        set: () => undefined,
        isEnabled: this.canReadVehicle,
        isDisabled: true
      },
      {
        title: "views.Setting.project.title",
        subtitle: "views.Setting.project.description",
        get: true,
        set: () => undefined,
        isEnabled: this.canReadTicket,
        isDisabled: true
      },
      {
        title: "views.Setting.isSharingEnabled.title",
        subtitle: "views.Setting.isSharingEnabled.description",
        get: true,
        set: () => undefined,
        isEnabled: this.canShareContent,
        isDisabled: true
      },
      {
        title: "views.Setting.isDocumentUploadEnabled.title",
        subtitle: "views.Setting.isDocumentUploadEnabled.description",
        get: true,
        set: () => undefined,
        isEnabled: this.canShareContent,
        isDisabled: true
      },
      {
        title: "views.Setting.admin.inBox.title",
        subtitle: "views.Setting.admin.inBox.description",
        get: true,
        set: () => undefined,
        isEnabled: this.canReadMessage,
        isDisabled: true
      },
      {
        title: "views.Setting.admin.template.title",
        subtitle: "views.Setting.admin.template.description",
        get: true,
        set: () => undefined,
        isEnabled: this.canReadTemplate,
        isDisabled: true
      },
      {
        title: "views.Setting.partnerAnalytics.title",
        subtitle: "views.Setting.partnerAnalytics.description",
        get: true,
        set: () => undefined,
        isEnabled: this.canReadAnalytics,
        isDisabled: true
      },
      {
        title: "views.Setting.isExtendedAcknowledgement.title",
        subtitle: "views.Setting.isExtendedAcknowledgement.description",
        get: FeatureModule.isExtendedAcknowledgement,
        set: FeatureModule.setIsExtendedAcknowledgement,
        isEnabled: true,
        isDisabled: true
      },
      {
        title: "views.Setting.qrCodeMultipleDownload.title",
        subtitle: "views.Setting.qrCodeMultipleDownload.description",
        get: FeatureModule.isQrCodeMultipleDownload,
        set: FeatureModule.setIsQrCodeMultipleDownload,
        isEnabled: true,
        isDisabled: true
      },
      {
        title: "views.Setting.isFileSelectionOnShortPressInImageUpload.title",
        subtitle: "views.Setting.isFileSelectionOnShortPressInImageUpload.description",
        get: FeatureModule.isFileSelectionOnShortPressInImageUpload,
        set: FeatureModule.setIsFileSelectionOnShortPressInImageUpload,
        isEnabled: true
      },
      {
        title: "views.Setting.isTpsActive.title",
        subtitle: "views.Setting.isTpsActive.description",
        get: FeatureModule.isTpsActive,
        set: FeatureModule.setIsTpsActive,
        isEnabled: true
      }
    ];
  }

  /**
   * List of settings to display in the admin section.
   */
  public get adminSettings(): ISettingItem[] {
    const adminSettings = [
      {
        title: "views.Setting.admin.debug.title",
        subtitle: "views.Setting.admin.debug.description",
        get: ConfigModule.debug,
        set: ConfigModule.setDebug,
        isEnabled: true
      }
    ];

    return adminSettings;
  }

  /**
   * Get the application version
   */
  get version() {
    return ConfigModule.appVersion;
  }

  /**
   * Checks if the user is authenticated.
   */
  get isAuthenticated() {
    return UserModule.isAuthenticated;
  }

  /**
   * Get the value if the feature settings are shown or not
   */
  get isShowingThgFeatureSettingsList(): boolean {
    return ConfigModule.navigationSettings.showThgFeatureSettings;
  }

  /**
   * Logs an error in the error log module,
   */
  public generateError() {
    ErrorLogModule.addErrorLog({
      name: "error",
      message: "This is an auto generated error message"
    });
  }
}

/**
 * Setting item interface
 */
interface ISettingItem {
  /**
   * i18n key for the title to be displayed as the header
   */
  title: string;

  /**
   * i18n key for the description of the setting item.
   */
  subtitle: string;

  /**
   * The value of the vuex store setting
   */
  get: boolean;

  /**
   * The set method of the vuex store setting
   */
  set: (value: boolean) => void;

  /**
   * Can the feature be enabled?
   */
  isEnabled: boolean;

  /**
   * Can the feature be disabled
   */
  isDisabled?: boolean;

  /**
   * Optional: A link for further details of the setting.
   */
  link?: string;
}
