import { AppContextEnum } from "@/lib/enum/appContext.enum";
import { ThgHttpClientProvider } from "@/services/thg/thg-http-client.provider";
import { ConfigModule } from "@/store/modules/config";
import { MrfiktivHttpClientProvider } from "../mrfiktiv/mrfiktiv-http-client.provider";
import { MrfiktivRatingViewmodelGen } from "../mrfiktiv/v1/data-contracts";
import { GetMyRatings as MrfiktivGetMyRatings } from "../mrfiktiv/v1/GetMyRatings";
import { ThgRatingViewmodelGen } from "../thg/v1/data-contracts";
import { GetMyRatings as ThgGetMyRatings } from "../thg/v1/GetMyRatings";

/**
 * Communicates with the thg me rating module or mrfiktiv me rating module depending on app context
 */
export class MeRatingService {
  /**
   * The proxy.
   */
  mrfiktivProxy: MrfiktivGetMyRatings;

  /**
   * The proxy.
   */
  thgProxy: ThgGetMyRatings;

  /**
   * @class Initialize Service
   */
  constructor(mrfiktivHttpClientProvider: MrfiktivHttpClientProvider, thgHttpClientProvider: ThgHttpClientProvider) {
    this.mrfiktivProxy = new MrfiktivGetMyRatings(mrfiktivHttpClientProvider.client());
    this.thgProxy = new ThgGetMyRatings(thgHttpClientProvider.client());
  }

  /**
   *
   * Creates a rating
   *
   * @returns
   */
  async findMyRatigns(): Promise<ThgRatingViewmodelGen[] | MrfiktivRatingViewmodelGen[] | undefined> {
    if (ConfigModule.appContext === AppContextEnum.THG_PORTAL || ConfigModule.appContext === AppContextEnum.THG) {
      return (await this.thgProxy.ratingMeControllerFindMyRatigns()).data;
    }

    if (ConfigModule.appContext === AppContextEnum.REPORT_PORTAL || ConfigModule.appContext === AppContextEnum.REPORT) {
      return (await this.mrfiktivProxy.ratingMeControllerFindMyRatigns()).data;
    }
  }
}

export default new MeRatingService(new MrfiktivHttpClientProvider(), new ThgHttpClientProvider());
