




















import ThgRatingForm from "@/components/rating/ThgRatingForm.vue";
import Debug from "@/components/utility/Debug.vue";
import LayoutReport from "@/layouts/LayoutReport.vue";
import { getDefaultPartnerColor } from "@/lib/getDefaultPartnerColor";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: { LayoutReport, Debug, ThgRatingForm }
})
export default class ThgRating extends Vue {
  get title() {
    return this.$t("report.thgRating.title");
  }

  get description() {
    return this.$t("report.thgRating.description");
  }

  get color() {
    return getDefaultPartnerColor();
  }
}
