/**
 * Copyright 2021 mmmint.ai info@mmmint.ai - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential to MMM Intelligence UG (haftungsbeschränkt).
 */

import { IKsrJobDetailViewmodel } from "@/lib/interfaces/ksr/ksr-job-detail-viewmodel.interface";
import { IKSRSetupInformation } from "@/lib/interfaces/setting/IKSRSetupInformation.interface";
import { AxiosInstance } from "axios";
import { MrfiktivHttpClientProvider } from "../mrfiktiv-http-client.provider";
import {
  MrfiktivKsrCustomerInfoViewModelGen,
  MrfiktivKsrExportReportDtoGen,
  MrfiktivOperationIdViewModelGen
} from "../v1/data-contracts";
import { Ksr } from "../v1/Ksr";

/**
 * FIXME: get type from generated client
 */
export interface IDaSetupViewModel {
  isSetup: boolean;
  isAuthenticated: boolean;
  isMandatoryConfiguration: boolean;
  error?: string;
  companyId?: string;
}

/**
 * Service to talk to mrfiktiv ksrService endpoint.
 */
export class KsrService {
  private client: AxiosInstance;

  private ksrClient: Ksr;

  constructor(clientProvider: MrfiktivHttpClientProvider) {
    this.client = clientProvider.instance();
    this.ksrClient = new Ksr(clientProvider.client());
  }

  /**
   * export a report to add data to an existing document
   *
   * @param partnerId
   * @param reportId
   * @param data
   */
  async ksrExportReport(
    partnerId: string,
    reportId: string,
    data: MrfiktivKsrExportReportDtoGen
  ): Promise<MrfiktivOperationIdViewModelGen> {
    const res = await this.ksrClient.ksrControllerExport(partnerId, reportId, data);

    return res.data;
  }
  /**
   * Export a report to the DA
   *
   * @param partnerId
   * @param tpsId
   * @param signRequestId
   * @returns
   */
  async exportSignToKsr(
    partnerId: string,
    tpsId: string,
    signRequestId: string
  ): Promise<MrfiktivOperationIdViewModelGen> {
    const res = await this.ksrClient.ksrControllerExportSignRequestToKsr(partnerId, tpsId, signRequestId);

    return res.data;
  }

  /**
   * gets all ksr documents for partner id and numberplate
   *
   * @param partnerId the partner id to get the documents for
   * @param numberPlate the numberplate to get the ksr documents for
   * @param numberOfReports the amount of reports to delete
   * @returns
   */
  async getJobDetailByNumberPlate(
    partnerId: string,
    numberPlate: string,
    numberOfReports: number,
    excludeJobsList: number[] = []
  ): Promise<IKsrJobDetailViewmodel[]> {
    const res = await this.ksrClient.ksrControllerGetJobDetailByNumberPlate({
      partnerId,
      numberPlate,
      numberOfJobs: numberOfReports,
      excludeJobsList
    });

    return res.data;
  }

  /**
   * Checks if Ksr Is setup for partner
   *
   * @param partnerId the id of the partner to check for if ksr is setup
   * @returns
   */
  async checkIsKsrSetup(partnerId: string): Promise<IDaSetupViewModel> {
    const res = await this.ksrClient.ksrControllerIsSetup(partnerId);

    return res.data;
  }

  /**
   * Sets up Ksr for partner
   *
   * @param partnerId
   * @param data
   */
  async setup(partnerId: string, data: IKSRSetupInformation): Promise<IDaSetupViewModel> {
    const res = await this.ksrClient.ksrControllerSetup(partnerId, data);

    return res.data;
  }

  /**
   * Looks for a job in ksr with jobId
   *
   * @param partnerId
   * @param jobId
   * @returns
   */
  async getJobDetailById(partnerId: string, jobId: string): Promise<IKsrJobDetailViewmodel> {
    const res = await this.ksrClient.ksrControllerGetJobDetailByJobId({ partnerId, jobId: Number(jobId) });

    return res.data;
  }

  /**
   * Looks up if a customer with the given email or phone number is already known in KSR
   * @param partnerId
   * @param email
   * @param phoneNumber
   * @returns
   */
  async getCustomerContactInfo(partnerId: string, email?: string, phoneNumber?: string) {
    const res = await this.ksrClient.ksrControllerGetCustomerContactInfo({
      partnerId,
      email: email ?? "",
      phoneNumber: phoneNumber ?? ""
    });

    return res.data;
  }

  /**
   * Looks up user details
   * @param partnerId
   * @param customerIdentityToken
   * @returns
   */
  async getUserDetails(partnerId: string, customerIdentityToken: string): Promise<MrfiktivKsrCustomerInfoViewModelGen> {
    const res = await this.client.get(`/partner/${partnerId}/ksrcustomer`, {
      headers: { customerIdentityToken: customerIdentityToken }
    });

    return res.data;
  }
}

export default new KsrService(new MrfiktivHttpClientProvider());
