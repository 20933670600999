/**
 * Copyright 2021 mmmint.ai info@mmmint.ai - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential to MMM Intelligence UG (haftungsbeschränkt).
 */

import { CountryCodeEnum } from "./country-code.enum";

/**
 * Language codes
 * TypeScript enum Language Codes ISO 639-1
 * @see https=//www.loc.gov/standards/iso639-2/
 */
export enum LanguageCodeEnum {
  AF = "af",
  SQ = "sq",
  AR = "ar",
  HY = "hy",
  EU = "eu",
  BE = "be",
  BN = "bn",
  BG = "bg",
  CA = "ca",
  ZH = "zh",
  HR = "hr",
  CS = "cs",
  DA = "da",
  NL = "nl",
  EN = "en",
  EO = "eo",
  ET = "et",
  FI = "fi",
  FR = "fr",
  GL = "gl",
  KA = "ka",
  DE = "de",
  DE_CH = "de_ch",
  EL = "el",
  GU = "gu",
  HE = "he",
  HI = "hi",
  HU = "hu",
  IS = "is",
  ID = "id",
  GA = "ga",
  IT = "it",
  JA = "ja",
  JV = "jv",
  KN = "kn",
  KK = "kk",
  KO = "ko",
  KU = "ku",
  KY = "ky",
  LV = "lv",
  LT = "lt",
  LB = "lb",
  MK = "mk",
  MG = "mg",
  MS = "ms",
  ML = "ml",
  MT = "mt",
  MI = "mi",
  MR = "mr",
  MN = "mn",
  NE = "ne",
  NO = "no",
  OR = "or",
  PS = "ps",
  FA = "fa",
  PL = "pl",
  PT = "pt",
  PA = "pa",
  RO = "ro",
  RU = "ru",
  SM = "sm",
  GD = "gd",
  SR = "sr",
  SI = "si",
  SK = "sk",
  SL = "sl",
  ES = "es",
  SU = "su",
  SW = "sw",
  SV = "sv",
  TL = "tl",
  TG = "tg",
  TA = "ta",
  TE = "te",
  TH = "th",
  BO = "bo",
  TR = "tr",
  UK = "uk",
  UR = "ur",
  UZ = "uz",
  VI = "vi",
  CY = "cy",
  XH = "xh",
  YI = "yi",
  YO = "yo",
  ZU = "zu"
}

/**
 * SQ, HY, EU, ZH, CS, DA, EN, EO, KA, EL, HE, HI, JA, JV, KK, KO, KU, MI, OR, FA, SU, SW, TA, TE, UK, UR, XH, YI, YO, ZU
 */
export const MissingCountryForLanguageMap = new Map<LanguageCodeEnum, CountryCodeEnum>([
  [LanguageCodeEnum.SQ, CountryCodeEnum.albania],
  [LanguageCodeEnum.HY, CountryCodeEnum.armenia],
  [LanguageCodeEnum.EU, CountryCodeEnum.spain],
  [LanguageCodeEnum.ZH, CountryCodeEnum.china],
  [LanguageCodeEnum.CS, CountryCodeEnum.czechRepublic],
  [LanguageCodeEnum.DA, CountryCodeEnum.denmark],
  [LanguageCodeEnum.EN, CountryCodeEnum.unitedStates],
  [LanguageCodeEnum.DE_CH, CountryCodeEnum.switzerland],
  [LanguageCodeEnum.EO, CountryCodeEnum.spain],
  [LanguageCodeEnum.KA, CountryCodeEnum.georgia],
  [LanguageCodeEnum.EL, CountryCodeEnum.greece],
  [LanguageCodeEnum.HE, CountryCodeEnum.israel],
  [LanguageCodeEnum.HI, CountryCodeEnum.india],
  [LanguageCodeEnum.JA, CountryCodeEnum.japan],
  [LanguageCodeEnum.JV, CountryCodeEnum.indonesia],
  [LanguageCodeEnum.KK, CountryCodeEnum.turkey],
  [LanguageCodeEnum.KO, CountryCodeEnum.korea],
  [LanguageCodeEnum.KU, CountryCodeEnum.iran],
  [LanguageCodeEnum.MI, CountryCodeEnum.newZealand],
  [LanguageCodeEnum.OR, CountryCodeEnum.india],
  [LanguageCodeEnum.FA, CountryCodeEnum.iran],
  [LanguageCodeEnum.SU, CountryCodeEnum.sudan],
  [LanguageCodeEnum.SW, CountryCodeEnum.tanzania],
  [LanguageCodeEnum.TA, CountryCodeEnum.india],
  [LanguageCodeEnum.TE, CountryCodeEnum.india],
  [LanguageCodeEnum.UK, CountryCodeEnum.ukraine],
  [LanguageCodeEnum.UR, CountryCodeEnum.pakistan],
  [LanguageCodeEnum.XH, CountryCodeEnum.southAfrica],
  [LanguageCodeEnum.YI, CountryCodeEnum.israel],
  [LanguageCodeEnum.YO, CountryCodeEnum.nigeria],
  [LanguageCodeEnum.ZU, CountryCodeEnum.southAfrica]
]);

export function showMissingCountries() {
  const missingCountryForLanguage = [];
  const countryCodes: string[] = Object.values(CountryCodeEnum);

  for (const l of Object.keys(LanguageCodeEnum).map(k => k.toUpperCase())) {
    if (!countryCodes.includes(l)) {
      missingCountryForLanguage.push(l);
    }
  }

  console.log(missingCountryForLanguage.join(", "));
}
