var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.isThrottled ? _c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("components.RatingForm.throttled")))]) : !_vm.isSubmitted ? _c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_vm.title ? _c('v-card-title', [_vm._v(_vm._s(_vm.title))]) : _vm._e(), _vm.description ? _c('v-card-text', [_vm._v(_vm._s(_vm.description))]) : _vm._e(), _c('v-rating', {
    staticClass: "pb-4 px-0",
    attrs: {
      "hover": "",
      "length": "5",
      "large": "",
      "disabled": _vm.isSubmitted,
      "background-color": _vm.color,
      "label": "Test"
    },
    model: {
      value: _vm.ratingEntity.rating,
      callback: function callback($$v) {
        _vm.$set(_vm.ratingEntity, "rating", $$v);
      },
      expression: "ratingEntity.rating"
    }
  }), _vm.ratingEntity.rating ? _c('div', [_c('v-textarea', {
    attrs: {
      "label": _vm.label,
      "outlined": "",
      "disabled": _vm.isSubmitted
    },
    model: {
      value: _vm.ratingEntity.message,
      callback: function callback($$v) {
        _vm.$set(_vm.ratingEntity, "message", $$v);
      },
      expression: "ratingEntity.message"
    }
  }), _c('v-card-actions', {
    staticClass: "px-0"
  }, [_c('v-btn', {
    attrs: {
      "dark": "",
      "color": _vm.color,
      "elevation": "0",
      "loading": _vm.ratingEntity.loading,
      "disabled": !_vm.ratingEntity.rating,
      "data-test-submit-rating": ""
    },
    on: {
      "click": _vm.submitRating
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("components.RatingForm.submit")) + " ")])], 1)], 1) : _vm._e()], 1) : _c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-text', {
    staticClass: "px-0"
  }, [_vm._v(" " + _vm._s(_vm.$t("components.RatingForm.thankYou")))]), _vm._t("default")], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }