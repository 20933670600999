






import { IRating } from "@/models/rating.entity";
import { Component, Prop, Vue } from "vue-property-decorator";
import RatingForm from "./RatingForm.vue";
import { ConfigModule } from "@/store/modules/config";

const CREATED = "created";

@Component({
  components: { RatingForm }
})
export default class ThgRatingForm extends Vue {
  @Prop({ default: "default" })
  ratingKey!: string;

  @Prop({ default: "" })
  title!: string;

  @Prop({ default: "" })
  description!: string;

  goToTrustpilot(rating: IRating) {
    if (rating.rating >= 4) {
      window.open(ConfigModule.thgRatingUrl, "_blank");
    }
    this.$emit(CREATED, rating);
  }
}
