import { AppContextEnum } from "@/lib/enum/appContext.enum";
import { ThgHttpClientProvider } from "@/services/thg/thg-http-client.provider";
import { ConfigModule } from "@/store/modules/config";
import { MrfiktivHttpClientProvider } from "../mrfiktiv/mrfiktiv-http-client.provider";
import {
  MrfiktivCreateRatingDtoGen,
  MrfiktivRatingControllerFindAllParamsGen,
  MrfiktivRatingViewmodelGen
} from "../mrfiktiv/v1/data-contracts";
import { Rating as MrfiktivRating } from "../mrfiktiv/v1/Rating";
import {
  ThgCreateRatingDtoGen,
  ThgRatingControllerFindAllParamsGen,
  ThgRatingViewmodelGen
} from "../thg/v1/data-contracts";
import { Rating as ThgRating } from "../thg/v1/Rating";

/**
 * Communicates with the thg rating module or mrfiktiv rating module depending on app context
 */
export class RatingService {
  /**
   * The proxy.
   */
  mrfiktivProxy: MrfiktivRating;

  /**
   * The proxy.
   */
  thgProxy: ThgRating;

  /**
   * @class Initialize Service
   */
  constructor(mrfiktivHttpClientProvider: MrfiktivHttpClientProvider, thgHttpClientProvider: ThgHttpClientProvider) {
    this.mrfiktivProxy = new MrfiktivRating(mrfiktivHttpClientProvider.client());
    this.thgProxy = new ThgRating(thgHttpClientProvider.client());
  }

  /**
   *
   * Creates a rating
   *
   * @returns
   */
  async create(
    partnerId: string,
    data: MrfiktivCreateRatingDtoGen | ThgCreateRatingDtoGen
  ): Promise<ThgRatingViewmodelGen | MrfiktivRatingViewmodelGen | undefined> {
    if (ConfigModule.appContext === AppContextEnum.THG_PORTAL || ConfigModule.appContext === AppContextEnum.THG) {
      return (await this.thgProxy.ratingControllerCreate(partnerId, data)).data;
    }

    if (ConfigModule.appContext === AppContextEnum.REPORT_PORTAL || ConfigModule.appContext === AppContextEnum.REPORT) {
      return (await this.mrfiktivProxy.ratingControllerCreate(partnerId, data)).data;
    }
  }

  async findAll(query: MrfiktivRatingControllerFindAllParamsGen | ThgRatingControllerFindAllParamsGen) {
    if (ConfigModule.appContext === AppContextEnum.THG_PORTAL) {
      return (await this.thgProxy.ratingControllerFindAll(query)).data;
    }

    if (ConfigModule.appContext === AppContextEnum.REPORT_PORTAL) {
      return (await this.mrfiktivProxy.ratingControllerFindAll(query)).data;
    }

    throw Error("Appcontext for not implemented: " + ConfigModule.appContext);
  }

  async findAllByPartnerId(
    partnerId: string,
    query: MrfiktivRatingControllerFindAllParamsGen | ThgRatingControllerFindAllParamsGen
  ) {
    if (ConfigModule.appContext === AppContextEnum.THG_PORTAL) {
      return (await this.thgProxy.ratingControllerFindByPartnerId({ partnerId, ...query })).data;
    }

    if (ConfigModule.appContext === AppContextEnum.REPORT_PORTAL) {
      return (await this.mrfiktivProxy.ratingControllerFindByPartnerId({ partnerId, ...query })).data;
    }

    throw Error("Appcontext for not implemented: " + ConfigModule.appContext);
  }
}

export default new RatingService(new MrfiktivHttpClientProvider(), new ThgHttpClientProvider());
