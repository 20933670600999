/**
 * Copyright 2021 mmmint.ai info@mmmint.ai - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential to MMM Intelligence UG (haftungsbeschränkt).
 */

import { NotificationModule } from "@/store/modules/notification.store";
import { BankingModule } from "@/store/modules/banking.store";
import { UserModule } from "@/store/modules/me-user.store";
import { NotificationFactory } from "./NotificationFactory";
import { ThgModule } from "@/store/modules/thg.store";
import { ChargingStationModule } from "@/store/modules/charging-station.store";
import { RatingMeModule } from "@/store/modules/rating-me.store";
/**
 * Checks for missing user information and creates a notification if necessary.
 */
export class MissingInformationHandler {
  static async check() {
    NotificationModule.clear();
    if (!UserModule.user?.userName && UserModule.isAuthenticated) {
      await UserModule.getCurrentUser();
    }

    if (UserModule.isAuthenticated) {
      const promises = [];

      if (!ChargingStationModule.charingStations.length) {
        promises.push(ChargingStationModule.getChargingStations());
      }

      if (!ThgModule.myThgs.length) {
        promises.push(ThgModule.getMyThgs());
      }

      if (!RatingMeModule.all.length) {
        promises.push(RatingMeModule.getMyRatings());
      }

      await Promise.all(promises);
    }

    const user = UserModule.user;

    if (ChargingStationModule.charingStations.length > 0 && !user?.contact?.phone) {
      NotificationFactory.createPhoneMissingNotification();
    }

    if (!user?.isMarketingOptIn) {
      NotificationFactory.createMarketingOptinMissingNotification();
    }

    for (const thg of ThgModule.myThgs) {
      if (thg.quickCheckoutEnabledFor?.length) {
        NotificationFactory.createNewGhghQuotaNotification();
        break;
      }
    }

    if (!user.address.street || !user.address.zip || !user.address.city) {
      NotificationFactory.createAddressMissingNotification();
    }

    if (user.isCompany && !user.company) {
      NotificationFactory.createCompanyNameMissingNotification();
    }

    if (user.isTaxDeductible && !user.taxnumber) {
      NotificationFactory.createTaxIdMissingNotification();
    }

    if (!BankingModule.myBanking?.iban) {
      BankingModule.getMyBanking().then(() => {
        const banking = BankingModule.myBanking;
        if (!banking.iban) {
          NotificationFactory.createIbanMissingNotification();
        }
      });
    }
  }
}
