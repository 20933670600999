import { Filter, FilterConfig, FilterTypes, IsFilterable } from "@/lib/filterable";
import { MrfiktivRatingViewmodelGen } from "@/services/mrfiktiv/v1/data-contracts";
import ratingService from "@/services/shared/ratingService";
import { ThgRatingViewmodelGen } from "@/services/thg/v1/data-contracts";
import { RatingMeModule } from "@/store/modules/rating-me.store";
import { RatingModule } from "@/store/modules/rating.store";
import { ITimestamp, Timestamp } from "./timestamp.entity";

@IsFilterable
class Rating {
  /**
   * isloading
   */
  loading = false;

  /** The id of the rating */
  @FilterConfig({
    type: FilterTypes.OBJECT_ID,
    displayName: "objects.rating.id"
  })
  id: string;

  /** The userid who created the rating */
  @FilterConfig({
    type: FilterTypes.OBJECT_ID,
    displayName: "objects.rating.userId"
  })
  userId: string;

  /** The given rating as a number */
  @FilterConfig({
    type: FilterTypes.NUMBER,
    displayName: "objects.rating.rating"
  })
  rating: number;

  /** A key to categorize ratings */
  @FilterConfig({
    type: FilterTypes.STRING,
    displayName: "objects.rating.key"
  })
  key: string;

  /** The id of the rated partner */
  @FilterConfig({
    type: FilterTypes.OBJECT_ID,
    displayName: "objects.rating.partnerId"
  })
  partnerId: string;

  /** An optional message */
  @FilterConfig({
    type: FilterTypes.STRING,
    displayName: "objects.rating.message"
  })
  message?: string;

  /** The timestamp with time of creation */
  @FilterConfig({
    type: Timestamp,
    displayName: "objects.rating.timestamp"
  })
  timestamp?: ITimestamp;

  constructor(data?: Partial<Rating | MrfiktivRatingViewmodelGen | ThgRatingViewmodelGen>) {
    this.id = data?.id || "";
    this.userId = data?.userId || "";
    this.rating = data?.rating ?? 0;
    this.key = data?.key || "";
    this.partnerId = data?.partnerId || "";
    this.message = data?.message || "";
    this.timestamp = new Timestamp(data?.timestamp);
  }

  async createRating(partnerId: string) {
    this.loading = true;
    try {
      const rating = await ratingService.create(partnerId, {
        rating: this.rating,
        message: this.message,
        key: this.key
      });
      if (!rating) {
        return;
      }

      const ratingEntity = new Rating(rating);

      RatingMeModule.setOne(ratingEntity);

      return ratingEntity;
    } catch (error) {
      RatingModule.setIsThrottled(true);
      throw error;
    } finally {
      this.loading = false;
    }
  }
}

type IRating = Rating;
const RatingEntity = Filter.createForClass(Rating);

export { IRating, RatingEntity };
