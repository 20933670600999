import { AbstractLocalDataAccessLayer } from "@/lib/utility/data/local-data-access-layer.abstract";
import { IRating, RatingEntity } from "@/models/rating.entity";

export const RatingDataAccessLayer = new (class extends AbstractLocalDataAccessLayer<IRating> {
  protected mapEntity(entity: IRating): IRating {
    return new RatingEntity(entity);
  }

  getIdentifier(entity: IRating): string {
    return entity.id;
  }

  mapsConfig = {};
})();
