




















import { RatingMeModule } from "@/store/modules/rating-me.store";
import { Component, Vue } from "vue-property-decorator";
import ThgRatingForm from "../rating/ThgRatingForm.vue";

@Component({
  components: { ThgRatingForm }
})
export default class ThgSuccessCard extends Vue {
  get askForFeedback() {
    return RatingMeModule.askForFeedback;
  }
  close() {
    this.$emit("close");
  }
}
