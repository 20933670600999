import { PageDataHandler } from "@/lib/utility/data/page-data-handler";
import { AbstractPageDataProvider } from "@/lib/utility/data/page-data-provider.abstract";
import { IPageViewModel } from "@/lib/utility/data/page-view-model.interface";
import { IRating, RatingEntity } from "@/models/rating.entity";
import { MrfiktivRatingControllerFindAllParamsGen } from "@/services/mrfiktiv/v1/data-contracts";
import ratingService from "@/services/shared/ratingService";
import {
  ThgChurnControllerGetAllPaginatedParamsGen,
  ThgRatingControllerFindAllParamsGen
} from "@/services/thg/v1/data-contracts";
import store from "@/store/VuexPlugin";
import { Action, Module, Mutation, getModule } from "vuex-module-decorators";
import { PaginatedBaseStore } from "../paginated-base.store";
import { RatingDataAccessLayer } from "./access-layers/rating.access-layer";
import { PaginationFilterListElement } from "./base-pagination.store";

const RatingPageDataProvider = new (class extends AbstractPageDataProvider<
  IRating,
  ThgRatingControllerFindAllParamsGen | MrfiktivRatingControllerFindAllParamsGen
> {
  itemsPerPage = 25;

  async getPage(query: ThgChurnControllerGetAllPaginatedParamsGen): Promise<IPageViewModel<IRating>> {
    const res = await ratingService.findAll(query);

    const meta = res.meta;
    const data = [];
    for (const entry of res.data ?? []) {
      data.push(new RatingEntity(entry));
    }

    return { meta, data };
  }
})();

@Module({
  dynamic: true,
  namespaced: true,
  name: "rating",
  store
})
export class RatingPaginationStore extends PaginatedBaseStore<
  IRating,
  ThgRatingControllerFindAllParamsGen | MrfiktivRatingControllerFindAllParamsGen
> {
  protected _data = RatingDataAccessLayer;
  protected _pageProvider = RatingPageDataProvider;
  protected _pager = new PageDataHandler(this._data, this._pageProvider);

  filterOptions: PaginationFilterListElement[] = RatingEntity?.filterables || [];

  private _ratingSubmitted = false;

  get ratingSubmitted(): boolean {
    return this._ratingSubmitted;
  }

  @Mutation
  updateRatingSubmitted(value: boolean) {
    this._ratingSubmitted = value;
  }

  @Action
  setRatingSubmitted(value: boolean) {
    this.context.commit("updateRatingSubmitted", value);
  }

  private _ratingKey = "";

  get ratingKey(): string {
    return this._ratingKey;
  }

  @Mutation
  updateRatingKey(value: string) {
    this._ratingKey = value;
  }

  @Action
  setRatingKey(value: string) {
    this.context.commit("updateRatingKey", value);
  }

  private _isThrottled = false;

  get isThrottled(): boolean {
    return this._isThrottled;
  }

  @Mutation
  updateIsThrottled(value: boolean) {
    this._isThrottled = value;
  }

  @Action
  setIsThrottled(value: boolean) {
    this.context.commit("updateIsThrottled", value);
  }
}

export const RatingModule = getModule(RatingPaginationStore);
