/**
 * Copyright 2021 mmmint.ai info@mmmint.ai - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential to MMM Intelligence UG (haftungsbeschränkt).
 */

import { IImageUploaded } from "@/models/Image/IImageUploaded";
import { MrfiktivCreateReportDtoGen } from "@/services/mrfiktiv/v1/data-contracts";

export interface ICreateReport extends Partial<MrfiktivCreateReportDtoGen> {
  message: string;
  damageLocation: string[];

  registrations: IImageUploaded[];
  cockpits: IImageUploaded[];
  damages: IImageUploaded[];
  damagesDetail: IImageUploaded[];
  overviews: IImageUploaded[];

  datePreference: string;

  isMarketingOptIn: boolean;
  isPrivacyChecked: boolean;

  assignees: string[];

  forwardedBy?: string;
}

export class CreateReport implements ICreateReport {
  message = "";
  damageLocation = [];
  registrations = [];
  cockpits = [];
  damages = [];
  damagesDetail = [];
  overviews = [];
  datePreference = "";

  forwardedBy = undefined;
  isMarketingOptIn = false;
  isPrivacyChecked = false;

  assignees = [];

  constructor(report?: ICreateReport) {
    if (report) {
      this.message = report.message;
      this.datePreference = report.datePreference;
      this.isMarketingOptIn = report.isMarketingOptIn;
      this.isPrivacyChecked = report.isPrivacyChecked;
    }
  }
}
