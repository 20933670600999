/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { MrfiktivExportReportMetaDtoGen, MrfiktivExportReportMetaViewModelGen } from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Meta<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags meta
   * @name ExportReportMetaControllerCreate
   * @summary Deprecated: Creates a document that contains meta information for an export
   * @request POST:/partner/{partnerId}/report/{reportId}/meta
   * @deprecated
   * @response `201` `MrfiktivExportReportMetaViewModelGen` Successfully created
   * @response `400` `void` Bad Request
   */
  exportReportMetaControllerCreate = (
    partnerId: string,
    reportId: string,
    data: MrfiktivExportReportMetaDtoGen,
    params: RequestParams = {}
  ) =>
    this.http.request<MrfiktivExportReportMetaViewModelGen, void>({
      path: `/partner/${partnerId}/report/${reportId}/meta`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags meta
   * @name ExportReportMetaControllerGetOne
   * @summary Gets a specific meta document
   * @request GET:/partner/{partnerId}/report/{reportId}/meta
   * @secure
   * @response `200` `(MrfiktivExportReportMetaViewModelGen)[]` Successfully created
   */
  exportReportMetaControllerGetOne = (partnerId: string, reportId: string, params: RequestParams = {}) =>
    this.http.request<MrfiktivExportReportMetaViewModelGen[], any>({
      path: `/partner/${partnerId}/report/${reportId}/meta`,
      method: "GET",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags meta
   * @name ExportReportMetaControllerGetAll
   * @summary Gets all meta documents for a partner
   * @request GET:/partner/{partnerId}/meta
   * @secure
   * @response `200` `(MrfiktivExportReportMetaViewModelGen)[]` Successfully created
   */
  exportReportMetaControllerGetAll = (partnerId: string, params: RequestParams = {}) =>
    this.http.request<MrfiktivExportReportMetaViewModelGen[], any>({
      path: `/partner/${partnerId}/meta`,
      method: "GET",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags meta
   * @name ExportReportMetaControllerDelete
   * @summary Deletes Meta Document by ID
   * @request DELETE:/partner/{partnerId}/meta/{metaId}
   * @secure
   * @response `204` `void` Successfully deleted
   */
  exportReportMetaControllerDelete = (partnerId: string, metaId: string, params: RequestParams = {}) =>
    this.http.request<void, any>({
      path: `/partner/${partnerId}/meta/${metaId}`,
      method: "DELETE",
      secure: true,
      ...params
    });
}
