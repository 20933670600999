




















































import Notifications from "@/components/thg/Notifications.vue";
import Debug from "@/components/utility/Debug.vue";
import LayoutReport from "@/layouts/LayoutReport.vue";
import { PartnerColor } from "@/lib/partnerColor";
import { PartnerTypeEnum } from "@/store/enum/partner/partner.type.enum";
import { ConfigModule } from "@/store/modules/config";
import { FeatureModule } from "@/store/modules/feature.store";
import { UserModule } from "@/store/modules/me-user.store";
import { NotificationModule } from "@/store/modules/notification.store";
import { PartnerModule } from "@/store/modules/partner";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {
    Notifications,
    LayoutReport,
    Debug
  }
})
export default class ThgOverview extends Vue {
  isLoading = false;

  get partnerColor() {
    return new PartnerColor();
  }

  get actions() {
    return [
      {
        click: this.sendMail,
        title: "report.thgOverview.contact",
        icon: "mdi-email",
        isOutlined: true,
        isEnabled: true
      },
      {
        click: this.toRating,
        title: "report.thgOverview.review",
        icon: "mdi-star",
        isOutlined: false,
        isEnabled: true
      }
    ].filter(x => x.isEnabled);
  }

  get navItems() {
    return [
      {
        title: "report.thgVehicleOverview.title",
        isEnabled: true,
        items: [
          {
            title: "report.thgOverview.vehicle.new",
            click: this.toNewCar,
            src: "https://static.mmmint.ai/wirkaufendeinethg/thg-overview-new-vehicle-1.jpeg",
            isEnabled: true
          },
          {
            title: "report.thgOverview.vehicle.overview",
            click: this.toVehicleOverview,
            src: "https://static.mmmint.ai/wirkaufendeinethg/parkplatz-1.jpeg",
            isEnabled: true
          }
        ].filter(x => x.isEnabled)
      },
      {
        title: "report.thgChargingStationOverview.title",
        isEnabled: this.isChargingStationEnabled,
        items: [
          {
            title: "report.thgOverview.chargingStation.overview",
            click: this.toChargingStationOverview,
            src: "https://static.mmmint.ai/wirkaufendeinethg/charging-station-1.jpeg",
            isEnabled: true
          }
        ].filter(x => x.isEnabled)
      },
      {
        title: "report.thgOverview.other",
        isEnabled: true,
        items: [
          {
            title: "report.thgOverview.marketplace.insuranceCheck.title",
            click: this.toInsuranceCheck,
            src: "https://static.mmmint.ai/wirkaufendeinethg/insurance-check-1.jpeg",
            isEnabled: this.isMarketplaceEnabled
          },
          {
            title: "report.thgOverview.billing",
            click: this.toBillings,
            src: "https://static.mmmint.ai/wirkaufendeinethg/thg-overview-contact-cash-1.jpeg",
            isEnabled: true
          },
          {
            title: "report.thgOverview.editProfile",
            click: this.toProfile,
            src: "https://static.mmmint.ai/wirkaufendeinethg/thg-overview-contact-data-1.jpeg",
            isEnabled: true
          },
          {
            title: "report.thgOverview.invite",
            click: this.toInviteFriends,
            src: "https://static.mmmint.ai/wirkaufendeinethg/thg-overview-invite-friends-1.jpeg",
            isEnabled: this.isInviteFriendsEnabled
          }
        ].filter(x => x.isEnabled)
      }
    ].filter(x => x.isEnabled);
  }

  get isMintFuture(): boolean {
    const isMintFuture = PartnerModule?.partner.partnerType === PartnerTypeEnum.THG_WHITE_LABEL_MINT_FUTURE;

    return isMintFuture;
  }

  get isPartnerMarketplace(): boolean {
    return PartnerModule?.partner?.settings?.bookedServices.marketplace || false;
  }

  get isChargingStationEnabled(): boolean {
    if (!PartnerModule.partner.settings) {
      return false;
    }

    if (!PartnerModule.partner.settings.bookedServices["chargingStation"]) {
      return false;
    }

    return PartnerModule.partner.settings.bookedServices.chargingStation;
  }

  get isMarketplaceEnabled(): boolean {
    return this.isPartnerMarketplace && FeatureModule.isMarketplaceEnabled.isInsuranceCheckEnabled;
  }

  closeNotification(index: number) {
    NotificationModule.popNotification(index);
  }

  get isAuthenticated(): boolean {
    return UserModule.isAuthenticated;
  }

  get title() {
    return `${this.$t("report.thgOverview.title")}  ${UserModule.user.firstName} ☀️`;
  }

  get description() {
    return this.$t("report.thgOverview.description");
  }

  get isInviteFriendsEnabled() {
    return FeatureModule.isInviteFriendsEnabled;
  }

  toNewCar() {
    this.$router.push({ path: "/thghome" });
  }

  toNewChargingStation() {
    this.$router.push({ path: "/charging-station/new" });
  }

  toVehicleOverview() {
    this.$router.push({ name: "ThgVehicleOverview" });
  }

  toChargingStationOverview() {
    this.$router.push({ name: "ThgChargingStationOverview" });
  }

  toProfile() {
    this.$router.push({ name: "Profile" });
  }

  toInviteFriends() {
    this.$router.push({ name: "ThgInviteFriends" });
  }

  toBillings() {
    this.$router.push({ name: "ThgBillingOverview" });
  }

  toInsuranceCheck() {
    this.$router.push({ name: "ThgInsuranceCheck" });
  }

  toRating() {
    this.$router.push({ name: "Rating" });
  }

  sendMail() {
    const mailText = `mailto:${ConfigModule.supportMail}`;

    window.open(mailText, "_blank");
  }
}
