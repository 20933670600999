




import { IMenuListItem } from "@/lib/interfaces/menu-list-item.interface";
import { PartnerTypeEnum } from "@/store/enum/partner/partner.type.enum";
import { FeatureModule } from "@/store/modules/feature.store";
import { UserModule } from "@/store/modules/me-user.store";
import { PartnerModule } from "@/store/modules/partner";
import { Component, Vue } from "vue-property-decorator";
import TheNavigationLayout from "./TheNavigationLayout.vue";

@Component({ components: { TheNavigationLayout } })
export default class TheThgList extends Vue {
  route(route: string) {
    this.$router.push({ name: route });
  }

  get isWhiteLabelPartner(): boolean {
    return PartnerModule?.partner.partnerType === PartnerTypeEnum.THG_WHITE_LABEL_QUOTE_AS_A_SERVICE;
  }

  get isChargingStationEnabled(): boolean {
    if (!PartnerModule.partner.settings) {
      return false;
    }

    if (!PartnerModule.partner.settings.bookedServices["chargingStation"]) {
      return false;
    }

    return PartnerModule.partner.settings.bookedServices.chargingStation;
  }

  /**
   *
   */
  get isAuthenticated() {
    return UserModule.isAuthenticated;
  }

  get items(): IMenuListItem[] {
    return [
      {
        icon: "mdi-home-outline",
        text: "report.home.home",
        link: "/overview",
        isEnabled: true
      },
      {
        icon: "mdi-plus",
        text: "report.thgOverview.action",
        link: "/thghome",
        isEnabled: true
      },
      {
        icon: "mdi-car-outline",
        text: "report.thgOverview.vehicle.title",
        link: "/vehicle",
        isEnabled: true
      },
      {
        icon: "mdi-ev-station",
        text: "report.thgOverview.chargingStation.title",
        link: "/charging-station",
        isEnabled: this.isChargingStationEnabled
      },
      {
        icon: "mdi-file-document-outline",
        text: "report.thgOverview.marketplace.insuranceCheck.title",
        link: "/insurance-check",
        isEnabled: this.isMarketplaceEnabled
      },
      {
        icon: "mdi-cash-multiple",
        text: "report.thgBillingOverview.title",
        link: "/billing",
        isEnabled: true
      },
      {
        icon: "mdi-account-multiple-plus-outline",
        text: "report.thgOverview.invite",
        link: "/invite",
        isEnabled: true
      },
      {
        icon: "mdi-account",
        text: "report.thgOverview.profile",
        link: "/profile",
        isEnabled: true
      }
    ];
  }

  get isMintFuture(): boolean {
    const isMintFuture = PartnerModule?.partner.partnerType === PartnerTypeEnum.THG_WHITE_LABEL_MINT_FUTURE;

    return isMintFuture;
  }

  get isPartnerMarketplace(): boolean {
    return PartnerModule?.partner?.settings?.bookedServices.marketplace || false;
  }

  /**
   * Show the marketplace if we are mint future, the partner booked the marketplace and we enabled it
   */
  get isMarketplaceEnabled(): boolean {
    return this.isPartnerMarketplace && FeatureModule.isMarketplaceEnabled.isInsuranceCheckEnabled;
  }
}
