/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  ThgCreateRatingDtoGen,
  ThgPageViewModelGen,
  ThgRatingControllerFindAllParamsGen,
  ThgRatingControllerFindByPartnerIdParamsGen,
  ThgRatingViewmodelGen
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Rating<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags rating
   * @name RatingControllerFindAll
   * @summary Get all ratings
   * @request GET:/rating
   * @secure
   * @response `200` `(ThgPageViewModelGen & { data?: (ThgRatingViewmodelGen)[] })`
   * @response `403` `void` Forbidden.
   */
  ratingControllerFindAll = (query: ThgRatingControllerFindAllParamsGen, params: RequestParams = {}) =>
    this.http.request<ThgPageViewModelGen & { data?: ThgRatingViewmodelGen[] }, void>({
      path: `/rating`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags rating
   * @name RatingControllerFindByPartnerId
   * @summary Get all ratings by partner id
   * @request GET:/partner/{partnerId}/rating
   * @secure
   * @response `200` `(ThgPageViewModelGen & { data?: (ThgRatingViewmodelGen)[] })`
   * @response `403` `void` Forbidden.
   * @response `404` `void` Not found.
   */
  ratingControllerFindByPartnerId = (
    { partnerId, ...query }: ThgRatingControllerFindByPartnerIdParamsGen,
    params: RequestParams = {}
  ) =>
    this.http.request<ThgPageViewModelGen & { data?: ThgRatingViewmodelGen[] }, void>({
      path: `/partner/${partnerId}/rating`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags rating
   * @name RatingControllerCreate
   * @summary Post a rating
   * @request POST:/partner/{partnerId}/rating
   * @secure
   * @response `201` `ThgRatingViewmodelGen` The created record
   * @response `400` `void` Bad Request.
   * @response `404` `void` Partner not found.
   * @response `429` `void` Too Many Requests
   */
  ratingControllerCreate = (partnerId: string, data: ThgCreateRatingDtoGen, params: RequestParams = {}) =>
    this.http.request<ThgRatingViewmodelGen, void>({
      path: `/partner/${partnerId}/rating`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
}
